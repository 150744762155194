import React from "react"
import ReactMarkdown from "react-markdown";
import SEO from "../components/common/seo";
import {graphql, Link} from "gatsby";
import LayoutSecondary from "../components/common/layout-secondary";
import {customElements, sortAsc} from "../utils/global-functions";
import rehypeRaw from "rehype-raw";

const PeoplePage = ({data, pageContext}) => {
    const lang = pageContext.locale.path.default;
    const langSlug = pageContext.locale.path.default !== 'en' ? '/' + pageContext.locale.path.default : '';
    const people = data.allStrapiPeople.edges[0].node;
    const benefits = people.benefits.sort(sortAsc);

    return (
        <LayoutSecondary pageContext={pageContext} cookies={data.cookies}>
            <SEO lang={lang} title={people.seo.title} description={people.seo.meta_description}/>

            {/** WELCOME PEOPLE
             ================================================== */}
            <section className="bg-white pt-2 pb-2">
                <div className="container">

                    {/** Heading */}
                    <h1 align="center" className="display-4 font-weight-bold spacing people">
                        {people.title}
                    </h1>

                </div>
                {/** / .container */}
            </section>

            {/** IMAGE PEOPLE (DESKTOP
             ================================================== */}
            <section className="bg-white p-desktop">
                <video autoPlay loop muted className="video__player">
                    <source src={require("../video/people.mp4").default}/>
                </video>
            </section>

            {/** IMAGE PEOPLE (MOBILE)
             ================================================== */}
            <section data-jarallax="" data-speed=".8" className="pt-12 pb-10 pt-md-15 pb-md-14 p-mobile bg-white"
                     style={{
                         background: "linear-gradient(rgba(255, 255, 255, 0.1),  rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0.3)), url('" + require('../images/p_01.png').default + "')",
                         backgroundSize: "cover",
                         backgroundPosition: "center"
                     }}>
                <br/><br/>
            </section>

            {/** PEOPLE RED
             ================================================== */}
            <section className="bg-grey pt-8 pb-9 pt-md-11 pb-md-10" style={{marginTop: "-10"}}>
                <div className="people-red">
                    <h3 align="center" className="display-6 font-weight-bold spacing color-tecalis">
                        "{people.subtitle}"
                    </h3>
                </div>
            </section>

            {/** TECALIS JOIN / STUDENTS
             ================================================== */}
            <section className="bg-grey pb-6 pb-md-11">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-offset-2" data-aos="fade-up">
                            <h3 align="center" className="display-4 font-weight-bold spacing">
                                {people.tcl_title}
                                <br/><br/>
                            </h3>

                            <ReactMarkdown children={people.tcl_description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 mb-6 spacing')}/>

                            <br/>

                            {pageContext.locale.path.default !== 'en' && <div align="center">
                                <Link to={langSlug + data.textWide.frontmatter.buttonStudents.link} className="btn btn-secondary spacing mr-2 mb-2">
                                    {data.textWide.frontmatter.buttonStudents.text}
                                </Link>
                                <Link to={langSlug + data.textWide.frontmatter.buttonJoin.link} className="btn btn-primary spacing mr-2 mb-2" style={{width: "initial"}}>
                                    {data.textWide.frontmatter.buttonJoin.text}
                                </Link>
                            </div>}
                        </div>
                    </div>
                </div>
            </section>

            {/** 6 PICKS
             ================================================== */}
            <section className="bg-white pt-11 pb-9 pt-md-11">
                <div className="container">
                    <br/>
                    <div className="row">
                        {benefits.map((benefit, i) => {
                            return <div className="col-12 col-xl-4" data-aos="fade-down" data-aos-delay={(i + 1) * 100} key={i}>
                                <h2 align="center" className="text-blue-grid font-weight-black">
                                    {benefit.title}
                                </h2>

                                <hr className="hr-sm my-6 my-md-8 border-blue-20"/>

                                <ReactMarkdown children={benefit.description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-sm text-black-80 mb-6 spacing')}/>
                                <br/>
                            </div>
                        })}
                    </div>

                </div>
                {/** / .container */}
            </section>

            {/** VIDEO
             ================================================== */}
            <section data-jarallax="" data-speed=".8" className="py-14 py-lg-16 jarallax"
                     style={{backgroundImage: "url('" + require('../images/people_01.png').default + "')"}}>

                {/** Shape */}
                <div className="shape shape-top shape-fluid-x svg-shim text-white">
                    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0h2880v125h-720L720 250H0V0z" fill="currentColor"/>
                    </svg>
                </div>

                {people.post && <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-8 col-lg-8">

                            {/** Text */}
                            <h2 className="font-size-xl text-tecalis mb-6 font-weight-bold spacing mt-6">
                                {people.post.description}
                            </h2>
                            {/** Button */}
                            <Link to={`/${langSlug}/noticias/${people.post.seo_url}`}
                                  className="btn btn-secondary"
                                  rel="noopener noreferrer"
                                  target="_blank">
                                <span className="h6 text-uppercase font-weight-bold">Ver noticia</span>
                            </Link>

                        </div>
                    </div>
                    {/** / .row */}
                </div>}
                {/** / .container */}

                {/** Shape */}
                {/**div className="shape shape-bottom shape-fluid-x svg-shim text-light">
                 <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M720 125L2160 0h720v250H0V125h720z" fill="#fff"/></svg>
                 </div*/}

            </section>

            {/** GALLERY PHOTOS / IMPLEMENTAR EASTER EGG - GAME TECALIS
             =========================================================== */}
            <section className="bg-white py-8 py-md-11">
                <div className="container-fluid">

                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12 col-lg-12 text-center">

                            {/** Image */}
                            <div className="form-row">
                                <div className="col-3">
                                    <div className="game--cards" data-card="1">
                                        <img src={require("../images/people_02.png").default} className="img-fluid" alt="..."/>
                                        <div className="after after-success"/>
                                        <div className="after after-error"/>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="game--cards" data-card="2">
                                        <img src={require("../images/people_03.png").default} className="img-fluid" alt="..."/>
                                        <div className="after after-success"/>
                                        <div className="after after-error"/>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="game--cards" data-card="3">
                                        <img src={require("../images/people_04.png").default} className="img-fluid" alt="..."/>
                                        <div className="after after-success"/>
                                        <div className="after after-error"/>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="game--cards" data-card="4">
                                        <img src={require("../images/people_05.png").default} className="img-fluid" alt="..."/>
                                        <div className="after after-success"/>
                                        <div className="after after-error"/>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                {/** / .container */}
            </section>
        </LayoutSecondary>
    );
};

export default PeoplePage

export const peoplePageQuery = graphql
    `query($lang: String) {
    allStrapiPeople (
        filter: { locale: { eq: $lang } }
    ) {
        edges {
            node {
                seo {
                    title
                    meta_description
                }
                title
                subtitle
                tcl_title
                tcl_description
                benefits {
                    order
                    title
                    description
                }
                post {
                    title
                    description
                    seo_url
                }
            }
        }
    }
    textWide: markdownRemark(
        frontmatter: {
            lang: { eq: $lang }
            name: { eq: "people/text-wide" }
        }
    ) {
        frontmatter {
            heading
            text
            buttonStudents {
                link
                text
            }
            buttonJoin {
                link
                text
            }
        }
    }
    cookies: markdownRemark(
        frontmatter: {
            lang: { eq: $lang }
            name: {
                eq: "cookies"
            }
        }
    ) {
        frontmatter {
            cookiesStart
            cookiesEnd
            linkText
        }
    }
}`;
